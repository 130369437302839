import { DataTabList } from '~/types/data-array'

const ReactAppDevTabList: DataTabList[] = [
  {
    name: 'Fitness PWA',
  },
  {
    name: 'Hiring platform',
  },
]

export default ReactAppDevTabList
