import { ExpertiseSectionMock } from '~/types/mock'

const ReactAppDevBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Startups',
    description:
      'New businesses often choose these services due to their cost-effectiveness and ability to build apps quickly. React’s reusable components and fast development cycle are ideal options for startups looking to develop and launch their products efficiently.',
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small & mid-scale businesses',
    description:
      'React’s modular architecture allows easy integration with existing systems and seamless updates, making it suitable for mid-sized businesses looking to enhance their online presence and user experience (UX).',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Enterprises',
    description:
      'Businesses with complex and large-scale apps place their trust in React Native app development services. Established companies, such as Walmart, Bloomberg, and Facebook, are known to utilize React Native for their app development needs.',
  },
]

export default ReactAppDevBusinesses
