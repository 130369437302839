import { ExpertiseSectionMock } from '~/types/mock'

const ReactAppDevPreferences: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_1',
    title: 'React Native development expertise',
    description:
      'Codica has a team of skilled React Native developers who deeply understand the framework and can leverage it to build robust and scalable mobile apps.',
  },
  {
    icon: 'spriteIcon_87',
    title: 'Expert quality assurance',
    description:
      'Codica’s QA specialists conduct thorough testing at each development stage to identify and resolve any issues before they impact the user experience.',
  },
  {
    icon: 'spriteIcon_42',
    title: 'Transparent communication',
    description:
      'Our team informs you of project progress throughout development. We use an agile approach with daily and weekly meetings so we can make changes to the project as soon as possible.',
  },
  {
    icon: 'spriteIcon_61',
    title: 'Customized solutions',
    description:
      'We take the time to understand your business goals and create a mobile application that aligns with your brand and vision.',
  },
  {
    icon: 'spriteIcon_35',
    title: 'Timely delivery',
    description:
      'We use agile development methodologies to ensure the project remains on course and is delivered on time and within budget.',
  },
  {
    icon: 'spriteIcon_27',
    title: 'Post-launch support',
    description:
      'Our team is available to address any issues or concerns that may arise and to provide ongoing support as needed.',
  },
]

export default ReactAppDevPreferences
