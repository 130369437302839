import { ExpertiseSectionMock } from '~/types/mock'

const ReactAppDevServices: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_10',
    title: 'React Native app consulting',
    description:
      'Our expert team of different professionals provides guidance on the best practices, technology stack, and UI/UX design to help clients make informed decisions and create successful apps.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'Mobile app design services',
    description:
      'Our design experts follow the latest design trends and ensure the app’s interface is intuitive and engaging, providing an excellent user experience.',
  },
  {
    icon: 'sprite3DIcon_4',
    title: 'MVP development services',
    link: '/services/mvp-development/',
    description:
      'We help clients quickly launch their apps with essential features, validate their ideas, and gather user feedback to iterate and improve the app based on real-world usage.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'React Native app development',
    description:
      'As experienced developers, we create full-fledged mobile apps with seasoned features using React Native framework, providing a consistent experience across different platforms.',
  },
  {
    icon: 'sprite3DIcon_6',
    title: 'React Native migration',
    description:
      'Codica ensures businesses a smooth transition of their existing mobile apps by leveraging their expertise in React Native app development and migration techniques.',
  },
  {
    icon: 'sprite3DIcon_20',
    title: 'Support and maintenance',
    description:
      'Our React Native app developers and DevOps specialists provide ongoing support and maintenance offering timely bug fixes, updates, and optimizations to keep the app up-to-date and running smoothly.',
  },
]

export default ReactAppDevServices
