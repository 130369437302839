import { Link } from 'gatsby'

import { ServicesProcessMock } from '~/types/mock'

const ReactAppDevProcess: ServicesProcessMock[] = [
  {
    num: '01',
    title: (
      <>
        <Link to="/services/product-discovery/" key="keyTitleProductDiscovery">
          Product discovery
        </Link>
      </>
    ),
    descr:
      'This stage involves understanding your app idea, defining its goals, objectives, features, and overall scope, and conducting market research to identify user needs and preferences.',
  },
  {
    num: '02',
    title: (
      <>
        <Link to="/services/ui-ux-design/" key="keyTitleDesign">
          UI/UX design
        </Link>
      </>
    ),
    descr:
      'Our UI/UX specialists design your app by creating wireframes and mockups to visualize the project’s layout, navigation, and user interface according to the latest design trends.',
  },
  {
    num: '03',
    title: 'Product development',
    descr:
      'Using the React Native framework, our development team writes reusable code components, implements app features and functionality, and integrates with APIs or backend systems.',
  },
  {
    num: '04',
    title: (
      <>
        <Link to="/services/quality-assurance/" key="keyTitleQA">
          QA testing
        </Link>
      </>
    ),
    descr:
      'Codica’s QA engineers conduct functional, performance, compatibility, and user acceptance testing to ensure the app is stable and secure and it provides a seamless user experience.',
  },
  {
    num: '05',
    title: 'Post-launch support',
    descr:
      'After the app is launched, our expert team provides ongoing maintenance and support to ensure the app’s smooth operation, timely updates, and bug fixes as needed.',
  },
]

export default ReactAppDevProcess
