import { ExpertiseSectionMock } from '~/types/mock'

const ReactAppDevToolkit: ExpertiseSectionMock[] = [
  {
    icon: 'serviceToolkitIcon12',
    title: 'React Native',
  },
  {
    icon: 'serviceToolkitIcon13',
    title: 'Figma',
  },
  {
    icon: 'serviceToolkitIcon14',
    title: 'TestFlight',
  },
  {
    icon: 'serviceToolkitIcon15',
    title: 'Firebase',
  },
  {
    icon: 'serviceToolkitIcon16',
    title: 'Google Play Console',
  },
  {
    icon: 'serviceToolkitIcon4',
    title: 'Sentry',
  },
  {
    icon: 'serviceToolkitIcon17',
    title: 'Visual Studio Code',
  },
  {
    icon: 'serviceToolkitIcon18',
    title: 'Jest',
  },
]

export default ReactAppDevToolkit
