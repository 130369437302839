import { useAddClassNameWhenVisible } from '~/hooks/useAddClassNameWhenVisible'
import { DataContentProps } from '~/types/helper'
import { ExpertiseSectionMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './ServicesToolkitSection.module.scss'

const ServicesToolkitSection = ({
  dataContent,
}: DataContentProps<ExpertiseSectionMock>) => (
  <div className={containerStyles.servicesToolkitSection}>
    <div className="row">
      {dataContent.map(({ icon, title }) => {
        const { ref } = useAddClassNameWhenVisible<HTMLDivElement>({
          targetClassName: containerStyles[`servicesToolkitSection__${icon}`],
          loadedClassName: containerStyles.servicesToolkitSection__sprite,
        })

        return (
          <div ref={ref} key={title} className="col-4 col-md-3">
            <div className={containerStyles.servicesToolkitSection__card}>
              <div
                className={cx(
                  containerStyles.servicesToolkitSection__icons,
                  containerStyles[`servicesToolkitSection__${icon}`],
                )}
              />
              <div className={containerStyles.servicesToolkitSection__title}>
                {title}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  </div>
)

export default ServicesToolkitSection
