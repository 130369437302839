import { ExpertiseSectionMock } from '~/types/mock'

const ReactAppDevBenefits: ExpertiseSectionMock[] = [
  {
    icon: 'spriteIcon_64',
    title: 'Reusability',
    description:
      'React Native developers can reuse a significant portion of the codebase, reducing development time and effort and improving overall code maintainability.',
  },
  {
    icon: 'spriteIcon_34',
    title: 'High performance',
    description:
      'With native components, React Native apps have smoother animations, faster load times, and overall better performance, providing a native-like experience to users.',
  },
  {
    icon: 'spriteIcon_78',
    title: 'Transparency',
    description:
      'By continuously being updated and improved, the React Native remains up-to-date with industry standards and best practices, and any bugs or issues are quickly addressed.',
  },
  {
    icon: 'spriteIcon_25',
    title: 'Cross-platform development',
    description:
      'Using a single codebase for different platforms streamlines the development process. Developers save time and effort, allowing for an app’s faster time to market.',
  },
  {
    icon: 'spriteIcon_22',
    title: 'Comprehensive toolset',
    description:
      'React Native offers many developer tools and libraries that provide powerful debugging and development capabilities for developers to build, test, and deploy React Native apps.',
  },
  {
    icon: 'spriteIcon_40',
    title: 'Real-time updates',
    description:
      'With a built-in live reload feature, React Native app developers can explore changes in the app’s user interface (UI) in real time without rebuilding the entire application.',
  },
]

export default ReactAppDevBenefits
