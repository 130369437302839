import { DevExpertiseCompanyMock } from '~/types/mock'

const ReactAppDevExpertise: DevExpertiseCompanyMock[] = [
  {
    title: '9+',
    text: 'years in touch',
  },
  {
    title: '$56+ mln',
    text: 'clients obtained',
  },
  {
    title: '50+',
    text: 'successful projects delivered',
  },
  {
    title: '60+',
    text: 'team size',
  },
]

export default ReactAppDevExpertise
