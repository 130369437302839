// extracted by mini-css-extract-plugin
export var reactAppDevBusinessSection = "s_ch";
export var reactAppDevCardBorderIconSection = "s_cg";
export var reactAppDevCaseSection = "s_cn";
export var reactAppDevClientsSection = "s_cq";
export var reactAppDevExpertiseSection = "s_cp";
export var reactAppDevIndustriesSection = "s_cj";
export var reactAppDevPreferencesSection = "s_cm";
export var reactAppDevProcessSection = "s_ck";
export var reactAppDevServicesIconSection = "s_cf";
export var reactAppDevTechStackSection = "s_cl";