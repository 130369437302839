import { StaticImage, getImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import { WorksTabsContent } from '~/types/data-array'
import { fileToImageLikeData } from '~/utils'
import useReactNativeAppDevelopmentStaticQuery from '~/views/Services/ReactNativeAppDevelopment/useReactNativeAppDevelopmentStaticQuery'

const photoClientWowner =
  '../../../assets/images/avatars/clients/client-wowner.png'
const photoClientImpact =
  '../../../assets/images/avatars/clients/client-impact.png'

const imageProps: CommonImageProps = {
  width: 50,
  height: 50,
}

const ReactAppDevTabContent = (): WorksTabsContent[] => {
  const query = useReactNativeAppDevelopmentStaticQuery()

  const portfolioREW = getImage(fileToImageLikeData(query.portfolioREW))
  const portfolioImpact = getImage(fileToImageLikeData(query.portfolioImpact))

  const tabContent = [
    {
      key: 'Fitness PWA',
      gradient: 'gradientImpact',
      image: portfolioImpact,
      header: 'PWA for fitness',
      title:
        'Impact is a fitness app that connects trainers and their clients via desktops and smartphones. The application allows for assigning and tracking personal training and nutrition programs.',
      resultsLeft: '400+ exercises included',
      resultsRight: '2000+ workouts performed',
      link: 'fitness-progressive-web-application',
      review:
        '“The Codica team is professional and very helpful. They changed the solution and added new features per our requests, and ensured the proper work of the app even with high loads on servers.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientImpact}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Richard Gunter',
      clientPosition: 'Founder of Impact Personal Training',
    },
    {
      key: 'Hiring platform',
      gradient: 'gradientRew',
      image: portfolioREW,
      header: 'Recruitment app',
      title:
        'RecruitEverywhere is a product of the Intelligence Group that specializes in talent acquisition and labor markets. The recruiting platform allows hiring specialists all over the world in a couple of clicks.',
      resultsLeft: 'Created a platform to hire staff members globally',
      resultsRight: 'Implemented the PWA approach',
      link: 'recruitment-progressive-web-application',
      review:
        '“I am happy to recommend Codica as a reliable team that can deliver unique and powerful software products. They used all their expertise when building our application, and often advised on the best way to implement a particular feature.”',
      get clientPhoto() {
        return (
          <StaticImage
            src={photoClientWowner}
            alt={`${this.clientName} | Codica`}
            title={this.clientName}
            {...imageProps}
          />
        )
      },
      clientName: 'Geert-Jan Waasdorp',
      clientPosition: 'Co-Founder of RecruitEverywhere',
    },
  ]

  return tabContent
}

export default ReactAppDevTabContent
