// extracted by mini-css-extract-plugin
export var servicesToolkitSection = "dl_zH";
export var servicesToolkitSection__card = "dl_zJ";
export var servicesToolkitSection__icons = "dl_zK";
export var servicesToolkitSection__serviceToolkitIcon1 = "dl_zM";
export var servicesToolkitSection__serviceToolkitIcon10 = "dl_zX";
export var servicesToolkitSection__serviceToolkitIcon11 = "dl_zY";
export var servicesToolkitSection__serviceToolkitIcon12 = "dl_zZ";
export var servicesToolkitSection__serviceToolkitIcon13 = "dl_z0";
export var servicesToolkitSection__serviceToolkitIcon14 = "dl_z1";
export var servicesToolkitSection__serviceToolkitIcon15 = "dl_z2";
export var servicesToolkitSection__serviceToolkitIcon16 = "dl_z3";
export var servicesToolkitSection__serviceToolkitIcon17 = "dl_z4";
export var servicesToolkitSection__serviceToolkitIcon18 = "dl_z5";
export var servicesToolkitSection__serviceToolkitIcon19 = "dl_z6";
export var servicesToolkitSection__serviceToolkitIcon2 = "dl_zN";
export var servicesToolkitSection__serviceToolkitIcon20 = "dl_z7";
export var servicesToolkitSection__serviceToolkitIcon3 = "dl_zP";
export var servicesToolkitSection__serviceToolkitIcon4 = "dl_zQ";
export var servicesToolkitSection__serviceToolkitIcon5 = "dl_zR";
export var servicesToolkitSection__serviceToolkitIcon6 = "dl_zS";
export var servicesToolkitSection__serviceToolkitIcon7 = "dl_zT";
export var servicesToolkitSection__serviceToolkitIcon8 = "dl_zV";
export var servicesToolkitSection__serviceToolkitIcon9 = "dl_zW";
export var servicesToolkitSection__sprite = "dl_zL";
export var servicesToolkitSection__title = "dl_z8";